import { auth } from "@/firebase";
import {
  signInWithCustomToken,
  onAuthStateChanged,
  // signInWithEmailAndPassword,
  signOut,
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import axios from "axios";
import { MOVEREADY_USER_API_URL, APP_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref } from "vue";
import Honeybadger from "@honeybadger-io/js";

const isUserAuthenticated = ref(false);
const SAVED_EMAIL_KEY = "moverly.auth.email.saved";
const user = ref(null);
const userId = ref(null);
const userEmail = ref(null);
const authError = ref(null);
// A combination or Firebase auth error codes and Moverly API HTTP codes.
const authErrorMessages = Object.freeze({
  "auth/invalid-email":
    "Your email doesn&rsquo;t match the sign-in email address. Check the email you entered and try again.",
  "auth/invalid-action-code":
    "Your sign-in link has been used or has expired. Request a new one to sign in.",
  "auth/user-not-found":
    "That email or password isn&rsquo;t correct. Please try again or contact us for assistance.",
  409: "That email address is already in use.",
  403: "Sorry, that email address allowed. Get in touch at support@moverly.com to find out more.",
});

const getAuthErrorMessage = (
  defaultMessage = "Please try again or contact us for assistance."
) => {
  const authErrorLookUp =
    authError.value?.code || authError.value?.response?.status;
  return authErrorMessages[authErrorLookUp] || defaultMessage;
};

const saveEmail = (email) => {
  try {
    window.localStorage.setItem(SAVED_EMAIL_KEY, email);
  } catch (ex) {
    // Do nothing.
  }
};

const deleteSavedEmail = () => {
  try {
    window.localStorage.removeItem(SAVED_EMAIL_KEY);
  } catch (ex) {
    // Do nothing.
  }
};

const getSavedEmail = () => {
  try {
    return window.localStorage.getItem(SAVED_EMAIL_KEY) || "";
  } catch (ex) {
    return "";
  }
};

const createUserAsAgent = async ({
  email,
  firstName,
  lastName,
  organisationName,
  source = "",
}) => {
  try {
    const appCheckToken = await getAppCheckToken();
    const signUserUpData = {
      email,
      organisationName,
      firstName,
      lastName,
      source,
    };
    await axios.post(`${MOVEREADY_USER_API_URL}/agents`, signUserUpData, {
      headers: {
        "X-Firebase-AppCheck": appCheckToken,
      },
    });
    authError.value = null;
  } catch (ex) {
    authError.value = ex;
    Honeybadger.notify(ex, {
      name: "auth.js",
      message: `Failed to create an agent's account`,
    });
    throw ex;
  }
};

const getAccessToken = () => user.value?.accessToken;

const signUserIn = async (email, continueUrl) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: continueUrl,
    // This must be true.
    handleCodeInApp: true,
  };
  saveEmail(email);
  return sendSignInLinkToEmail(auth, email, actionCodeSettings);
};

const signUserInWithEmailAuthentication = async (email) => {
  const auth = getAuth();

  if (isSignInWithEmailLink(auth, window.location.href)) {
    try {
      const result = await signInWithEmailLink(
        auth,
        email,
        window.location.href
      );
      deleteSavedEmail();
      return result.user;
    } catch (ex) {
      authError.value = ex;
      Honeybadger.notify(ex, {
        name: "auth.js",
        message: `isSignInWithEmailLink failed`,
      });
      throw ex;
    }
  } else {
    const emailLinkError = new Error("No email sign-in link found.");
    authError.value = emailLinkError.message;
    throw emailLinkError;
  }
};

const requestEmailLink = async (email, continueUrl) => {
  try {
    const appCheckToken = await getAppCheckToken();
    await axios.post(
      `${MOVEREADY_USER_API_URL}/send-sign-in-email`,
      {
        email,
        continueUrl,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    authError.value = null;
  } catch (ex) {
    authError.value = ex;
    Honeybadger.notify(ex, {
      name: "auth.js",
      message: `Failed to request sign-in email`,
    });
    throw ex;
  }
};

const signUserUp = async (email, continueUrl) => {
  try {
    const appCheckToken = await getAppCheckToken();
    await axios.post(
      `${MOVEREADY_USER_API_URL}/sign-up`,
      {
        email,
        continueUrl,
      },
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    authError.value = null;
  } catch (ex) {
    authError.value = ex;
    Honeybadger.notify(ex, {
      name: "auth.js",
      message: `Failed to create a user account`,
    });
    throw ex;
  }
};

const signUserInWithCustomToken = async (token) => {
  try {
    await signInWithCustomToken(auth, token);
  } catch (ex) {
    authError.value = ex;
    throw ex;
  }
};

const signUserOut = async () => {
  signOut(auth);
};

onAuthStateChanged(auth, (firebaseUser) => {
  if (firebaseUser) {
    user.value = firebaseUser;
    userEmail.value = firebaseUser.email;
    userId.value = firebaseUser.uid;
    isUserAuthenticated.value = true;
  } else {
    user.value = null;
    userEmail.value = null;
    userId.value = null;
    isUserAuthenticated.value = false;
  }
});

const createReturnRoute = (returnUrl) => {
  const returnUrlObject = new URL(`${APP_URL}${returnUrl}`);
  const returnUrlQuery = Object.fromEntries(returnUrlObject.searchParams);
  return {
    path: returnUrlObject.pathname,
    query: returnUrlQuery,
  };
};

export default function useAuth() {
  return {
    auth,
    authError,
    signUserUp,
    authErrorMessages,
    createReturnRoute,
    createUserAsAgent,
    deleteSavedEmail,
    getAccessToken,
    getAuthErrorMessage,
    getSavedEmail,
    isUserAuthenticated,
    requestEmailLink,
    saveEmail,
    signUserIn,
    signUserInWithCustomToken,
    signUserInWithEmailAuthentication,
    signUserOut,
    user,
    userEmail,
    userId,
  };
}
