import Honeybadger from "@honeybadger-io/js";
import useChecks from "@/composables/checks";
import { FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS } from "@/config";
import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";
import { ref, computed } from "vue";
import { checkSeverity } from "@/enums/checks";

const { checks, filterFlagsByName } = useChecks();

const isFinalisingSaleReady = ref(false);

const isSaleReadyFinalised = computed(() => {
  const saleReadyFinalisedFlag = filterFlagsByName(
    "saleReady",
    checks.value,
    true
  );

  return saleReadyFinalisedFlag?.severity === checkSeverity.SUCCESS;
});

const finaliseSaleReady = async (transactionId, options) => {
  isFinalisingSaleReady.value = true;
  const finaliseSaleReady = httpsCallable(
    functions,
    "collectors-finaliseSaleReady",
    {
      limitedUseAppCheckTokens: FIREBASE_ENABLE_LIMITED_USE_APP_CHECK_TOKENS,
    }
  );

  try {
    await finaliseSaleReady({ transactionId, ...options });
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "saleReady.js",
      message: `Failed to finalise Sale Ready`,
      params: {
        transactionId,
        options,
      },
    });
    isFinalisingSaleReady.value = false;
    throw ex;
  }
};

export default function useSaleReady() {
  return {
    finaliseSaleReady,
    isFinalisingSaleReady,
    isSaleReadyFinalised,
  };
}
