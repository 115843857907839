import useUserProfile from "@/composables/userProfile";
import useOrganisation from "@/composables/organisation/organisation";

const requiresOrganisationUsers = async (to, from, next) => {
  const { organisationId } = useUserProfile();
  const { fetchOrganisationUsers, hasFetchedOrganisationUsers } =
    useOrganisation();

  if (!hasFetchedOrganisationUsers) {
    await fetchOrganisationUsers(organisationId.value);
  }
  next();
};

export default requiresOrganisationUsers;
