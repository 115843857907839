import axios from "axios";
import useAuth from "@/composables/auth";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";
import crmName from "@/enums/crm-name";
import useOrganisationSettings from "@/composables/organisation/organisationSettings";
import useBackOfficeBranding from "@/composables/brandingBackOffice";

const { setBranding } = useBackOfficeBranding();
const { getAccessToken } = useAuth();
const { setOrganisationSettings } = useOrganisationSettings();

const conveyancers = ref([]);
const isFetchingOrganisation = ref(false);
const isFetchingOrganisationUsers = ref(false);
const organisation = ref(null);
const organisationUsers = ref([]);
let hasFetchedOrganisationUsers = false;

const fetchOrganisation = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  isFetchingOrganisation.value = true;

  try {
    const response = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisation.value = response.data;
    setOrganisationSettings(response.data?.preferences || {});
    setBranding(response.data?.preferences?.backOfficeBranding || {});
  } catch (ex) {
    organisation.value = null;
    console.error(ex);
  } finally {
    isFetchingOrganisation.value = false;
  }
};

const hasConveyancers = computed(() => !!conveyancers.value.length);

const fetchOrganisationByInviteToken = async (inviteToken) => {
  const appCheckToken = await getAppCheckToken();
  isFetchingOrganisation.value = true;
  try {
    const findOrganisationResponse = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/invite-token/${inviteToken}`,
      {
        headers: {
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisation.value = findOrganisationResponse.data;
  } catch (ex) {
    organisation.value = null;
    return null;
  } finally {
    isFetchingOrganisation.value = false;
  }
};

const organisationLogoUrl = computed(() => {
  const organisationLogo = organisation.value?.organisationLogo;

  if (!organisationLogo) return "";

  if (organisationLogo.startsWith("http")) return organisationLogo;

  return `/assets/vendors/${organisationLogo}`;
});

const organisationName = computed(
  () => organisation.value?.organisationName || ""
);

const fetchOrganisationUsers = async (organisationId) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  isFetchingOrganisationUsers.value = true;

  try {
    const organisationUsersResponse = await axios.get(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/users`,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
    organisationUsers.value = organisationUsersResponse.data;
  } catch (ex) {
    organisationUsers.value = [];
  } finally {
    hasFetchedOrganisationUsers = true;
    isFetchingOrganisationUsers.value = false;
  }
};

const getIntegrationsForAllUsers = (organisationUsers) => {
  return organisationUsers.reduce((acc, user) => {
    return [...acc, ...(user?.crmIntegrations || [])];
  }, []);
};

const isAltoIntegrationAvailable = computed(() => {
  const integrationsForAllUsers = getIntegrationsForAllUsers(
    organisationUsers.value
  );

  const hasAltoIntegration = integrationsForAllUsers.some(
    (integration) => integration?.crmName === crmName.ALTO
  );

  return hasAltoIntegration;
});

/**
 * Checking all users for valid Alto integration. In case
 * some users don't have it enabled, this will disable it for
 * all users in the organisation. In taht case the invite/accept
 * flow hasn't copied the integration record accross.
 */
const isAltoIntegrationActive = computed(() => {
  const integrationsForAllUsers = getIntegrationsForAllUsers(
    organisationUsers.value
  );
  const altoIntegrations = integrationsForAllUsers.filter(
    (integration) => integration?.crmName === crmName.ALTO
  );
  const activeIntegrations = altoIntegrations.filter(({ accountId }) => {
    return accountId !== undefined && accountId !== "";
  });

  return (
    altoIntegrations.length > 0 &&
    activeIntegrations.length === altoIntegrations.length
  );
});

const updateOrganisation = async ({
  organisationName = null,
  organisationLogoFileData = null,
  address = null,
  organisationId,
}) => {
  const appCheckToken = await getAppCheckToken();
  const userAccessToken = getAccessToken();
  const organisationData = {};

  if (organisationName) {
    organisationData.organisationName = organisationName;
  }

  if (organisationLogoFileData) {
    organisationData.organisationLogoFileData = organisationLogoFileData;
  }

  if (address) {
    organisationData.address = address;
  }

  const newOrganisation = await axios.patch(
    `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}`,
    organisationData,
    {
      headers: {
        Authorization: `Bearer ${userAccessToken}`,
        ContentType: "application/json",
        "X-Firebase-AppCheck": appCheckToken,
      },
    }
  );
  // organisations.value = [...organisations.value, newOrganisation.data];
  return newOrganisation;
};

export default function useOrganisation() {
  return {
    conveyancers,
    fetchOrganisation,
    fetchOrganisationByInviteToken,
    fetchOrganisationUsers,
    hasConveyancers,
    isAltoIntegrationActive,
    isAltoIntegrationAvailable,
    isFetchingOrganisation,
    isFetchingOrganisationUsers,
    hasFetchedOrganisationUsers,
    organisation,
    organisationLogoUrl,
    organisationName,
    organisationUsers,
    updateOrganisation,
  };
}
