import Honeybadger from "@honeybadger-io/js";
import axios from "axios";
import participantRoles from "@/enums/participant-roles";
import useAuth from "@/composables/auth";
import useIdentification from "@/composables/identification/identification";
import { MOVEREADY_ORGANISATION_API_URL } from "@/config";
import { getAppCheckToken } from "@/firebase";
import { ref, computed } from "vue";

const { identityProvider: identityProviderName } = useIdentification();
const { getAccessToken } = useAuth();

const isBuyerIdentityChecksEnabled = ref(null);
const isSellerIdentityChecksEnabled = ref(null);
const isClientIdentityChecksEnabled = computed(
  () => isSellerIdentityChecksEnabled.value
);
const isApplicantIdentityChecksEnabled = computed(
  () => isBuyerIdentityChecksEnabled.value
);
const isPropertyPackEnabled = ref(null);
const isLegalPartnershipEnabled = ref(null);
const isSellerContractReadyPaymentEnabled = ref(null);
const identityProvider = ref(identityProviderName.ONFIDO);
const isConnellsSaleReadyEnabled = ref(null);
const isClientThirdPartyConsentRequired = ref(false);
const isLmsWorkspaceCreationRequired = ref(false);
const isMatchmakerIdRequired = ref(false);
const isIdentityProviderSettingsDisabled = ref(false);
const isIdentityProviderSettingEnabled = ref(false);
const isSaleReadyEnabled = ref(false);
const configuredUpgradeProducts = ref({});
const offices = ref([]);
const emails = ref({});

const emailRecipient = Object.freeze({
  AGENTS_ASSIGNED_TO_TRANSACTION: "agents",
  ORGANISATION_RECIPIENTS: "thirdParty",
});

// New default settings
const isSellerIdentityCheckRequiredByDefault = ref(null);
const isLandlordIdentityCheckRequiredByDefault = ref(null);
const isSavingSettings = ref(false);

const getSortedOffices = (officesPreference) => {
  const offices = Array.isArray(officesPreference) ? officesPreference : [];

  return offices.sort((office, officeToCompate) => {
    if (office < officeToCompate) {
      return -1;
    }
    if (office > officeToCompate) {
      return 1;
    }
    return 0;
  });
};

const setOrganisationSettings = (preferences = {}) => {
  if (preferences?.identityProvider) {
    identityProvider.value = preferences.identityProvider;
  }

  emails.value = preferences.emails || {};
  offices.value = getSortedOffices(preferences?.offices);
  isMatchmakerIdRequired.value = preferences?.isMatchmakerIdRequired === true;
  isLmsWorkspaceCreationRequired.value =
    preferences?.isLmsWorkspaceCreationRequired === true;

  configuredUpgradeProducts.value =
    preferences?.configuredUpgradeProducts || {};

  isSaleReadyEnabled.value =
    typeof preferences?.isSaleReadyEnabled === "boolean"
      ? preferences?.isSaleReadyEnabled
      : false;

  isIdentityProviderSettingsDisabled.value =
    typeof preferences?.isIdentityProviderSettingsDisabled === "boolean"
      ? preferences?.isIdentityProviderSettingsDisabled
      : false;

  isIdentityProviderSettingEnabled.value =
    typeof preferences?.isIdentityProviderSettingEnabled === "boolean"
      ? preferences?.isIdentityProviderSettingEnabled
      : false;

  isClientThirdPartyConsentRequired.value =
    typeof preferences?.isClientThirdPartyConsentRequired === "boolean"
      ? preferences?.isClientThirdPartyConsentRequired
      : null;

  isSellerIdentityCheckRequiredByDefault.value =
    typeof preferences?.isSellerIdentityCheckRequiredByDefault === "boolean"
      ? preferences?.isSellerIdentityCheckRequiredByDefault
      : null;

  isLandlordIdentityCheckRequiredByDefault.value =
    typeof preferences?.isLandlordIdentityCheckRequiredByDefault === "boolean"
      ? preferences?.isLandlordIdentityCheckRequiredByDefault
      : null;

  isConnellsSaleReadyEnabled.value =
    typeof preferences?.isConnellsSaleReadyEnabled === "boolean"
      ? preferences?.isConnellsSaleReadyEnabled
      : null;

  isSellerIdentityChecksEnabled.value =
    typeof preferences?.isSellerIdentityChecksEnabled === "boolean"
      ? preferences?.isSellerIdentityChecksEnabled
      : null;

  isBuyerIdentityChecksEnabled.value =
    typeof preferences?.isBuyerIdentityChecksEnabled === "boolean"
      ? preferences?.isBuyerIdentityChecksEnabled
      : null;

  isPropertyPackEnabled.value =
    preferences?.isPropertyPackEnabled === "boolean"
      ? preferences?.isPropertyPackEnabled
      : null;

  isLegalPartnershipEnabled.value =
    typeof preferences?.isLegalPartnershipEnabled === "boolean"
      ? preferences?.isLegalPartnershipEnabled
      : null;

  isSellerContractReadyPaymentEnabled.value =
    typeof preferences?.isSellerContractReadyPaymentEnabled === "boolean"
      ? preferences?.isSellerContractReadyPaymentEnabled
      : null;
};

const updateOrganisationSettings = async (organisationId, options = {}) => {
  isSavingSettings.value = true;

  try {
    const appCheckToken = await getAppCheckToken();
    const userAccessToken = getAccessToken();
    await axios.patch(
      `${MOVEREADY_ORGANISATION_API_URL}/organisations/${organisationId}/preferences`,
      options,
      {
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          ContentType: "application/json",
          "X-Firebase-AppCheck": appCheckToken,
        },
      }
    );
  } catch (ex) {
    Honeybadger.notify(ex, {
      name: "organisationSettings.js",
      message: `Failed to update organisation settings for organisationId: ${organisationId}`,
      params: {
        organisationId,
        options,
      },
    });
    throw ex;
  } finally {
    isSavingSettings.value = false;
  }
};

/**
 * This might be a specific preference flag at some point, for
 * now the inference is if there are offices, then office management is enabled.
 */
const isOfficeManagementEnabled = computed(() => offices.value.length > 0);

const isIdentityCheckRequiredByRole = (participantRole) => {
  const isClientInvite = [
    participantRoles.SELLER,
    participantRoles.LANDLORD,
  ].includes(participantRole);
  const isApplicantInvite = [
    participantRoles.BUYER,
    participantRoles.TENANT,
  ].includes(participantRole);

  // Applicant (buyer/tenant) identity checks are enabled by default as that's the only step they have currently.
  if (
    (isClientIdentityChecksEnabled.value && isClientInvite) ||
    isApplicantInvite
  )
    return true;

  return false;
};

/**
 * This may become organisaiton specific, but it's also
 * used in several places so the copy is set here to be reused.
 */
const thirdPartyConsentLabel =
  "I confirm compliance with applicable terms, conditions, and GDPR requirements, including obtaining necessary consents";

const hasConfiguredUpgradeProducts = computed(() => {
  const { purchasedProducts = [], requiresPurchase = [] } =
    configuredUpgradeProducts.value || {};

  return [...purchasedProducts, ...requiresPurchase].length > 0;
});

export default function useOrganisationSettings() {
  return {
    emailRecipient,
    emails,
    hasConfiguredUpgradeProducts,
    identityProvider,
    isApplicantIdentityChecksEnabled,
    isBuyerIdentityChecksEnabled,
    isClientIdentityChecksEnabled,
    isClientThirdPartyConsentRequired,
    isConnellsSaleReadyEnabled,
    isIdentityCheckRequiredByRole,
    isIdentityProviderSettingEnabled,
    isIdentityProviderSettingsDisabled,
    isLandlordIdentityCheckRequiredByDefault,
    isLegalPartnershipEnabled,
    isLmsWorkspaceCreationRequired,
    isMatchmakerIdRequired,
    isOfficeManagementEnabled,
    isPropertyPackEnabled,
    isSaleReadyEnabled,
    isSellerContractReadyPaymentEnabled,
    isSellerIdentityCheckRequiredByDefault,
    isSellerIdentityChecksEnabled,
    offices,
    setOrganisationSettings,
    thirdPartyConsentLabel,
    updateOrganisationSettings,
    configuredUpgradeProducts,
  };
}
